import React, { useState } from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"

export default ({ data }) => {
    const [selectedPoint, setSelectedPoint] = useState(0)
    const [contentVisible, setContentVisible] = useState(false)
    
    const handleSelectedPoint = index => {
        setSelectedPoint(index)

        setContentVisible(true)

        setTimeout(function(){
            setContentVisible(false)
        }, 500)
    }

	const {
		title,
        content,
        textFields: {
            textPage: sections
        }
    } = data.siteFields.page

    let excerpt = ''

    if (content) {
        excerpt = content.replace(/(<([^>]+)>)/ig,"")
        excerpt = excerpt.slice(0,160)
    }

	return (
		<>
			<SEO title={title} description={excerpt} />
            <div className="textpage">
                <div className="container">
                    <div className="textpage-hero">
                        <div className="textpage-hero-text">
                            <h1 dangerouslySetInnerHTML={{ __html: title }} />
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                        <div className="textpage-hero-decoration">
                            <div />
                        </div>
                    </div>
                </div>
                <div className={contentVisible ? 'container hide' : 'container'}>
                    <div className="loader">
                        <div />
                    </div>
                    <div className="textpage-content">
                        <div className="textpage-content-cont">
                            <ul className="textpage-content-links">
                                { sections && sections.map((row, index) => (
                                    <li key={index } className={index === selectedPoint ? 'active' : '' } onClick={() => handleSelectedPoint(index)}>
                                        { row.title }
                                    </li>
                                ))}
                            </ul>
                            <ul className="textpage-content-copy">
                                { sections && sections.map((row, index) => (
                                    <li key={index } className={index === selectedPoint ? 'active' : ''}>
                                        <h3 dangerouslySetInnerHTML={{ __html: row.title }} />
                                        <div dangerouslySetInnerHTML={{ __html: row.copy }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
		</>
	)
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        date
        content(format: RENDERED)
        textFields {
            textPage {
                title
                copy
            }
        }
      }
    }
  }
`
